@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300;400;700&display=swap");
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  font-family: "Ubuntu", sans-serif;
}

@media (max-width: 800px) {
  html {
    font-size: 50%;
  }
}
.page-container {
  display: grid;
  grid-template-columns: 15rem 1fr;
  grid-template-rows: 8rem 1fr;
}

@media (max-width: 550px) {
  .container {
    grid-template-columns: 100%;
    grid-template-rows: 8rem 1fr;
  }
}
.header {
  background-color: black;
  grid-column: 1/-1;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4rem;
  font-size: 1.5rem;
  z-index: 3;
}
.header .icons-group {
  display: flex;
  gap: 2rem;
}
.header__mobile-icons {
  display: none;
}
.header__spotify-login {
  display: flex;
  align-items: center;
  height: 8rem;
}
.header__spotify-login--logo {
  height: 50%;
}

@media (max-width: 550px) {
  .header {
    position: fixed;
    width: 100%;
    height: 8lvh;
    justify-content: space-between;
    padding: 0 4rem;
  }
  .header__mobile-icons {
    display: flex;
    align-items: center;
    transition: all 0.5s ease-out;
  }
  .header__mobile-icons.fade {
    opacity: 0.5;
  }
  .header__mobile-icons--menu {
    display: block;
    height: 4.5rem;
    width: 4.5rem;
    cursor: pointer;
    transition: all 0.5s;
  }
  .header__mobile-icons--undo {
    display: block;
    height: 3.5rem;
    width: 3.5rem;
    position: absolute;
    visibility: hidden;
    opacity: 0;
    transition: all 1.5s ease-out;
    cursor: pointer;
  }
  .header__mobile-icons--undo.visible {
    opacity: 1;
    visibility: visible;
    transform: translateX(6rem);
  }
}
.sidebar {
  height: 100%;
  width: 15rem;
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sidebar__form {
  background-color: #a7c4e3;
  padding: 1rem;
  padding-top: 4rem;
  font-size: 2rem;
  margin-top: 5rem;
  margin-left: 2rem;
  border-radius: 10%;
  text-align: center;
  box-shadow: 0.25rem 0.25rem 0.5rem lightgrey;
  font-weight: 700;
  text-transform: uppercase;
  color: white;
}
.sidebar__form + .winners {
  background-color: rgb(180, 139, 184);
}
.sidebar__form + .genre {
  background-color: rgb(143, 184, 139);
}
.sidebar__form--select {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1rem;
  padding: 0.25rem;
  text-align: center;
  border: none;
  background-color: inherit;
  color: white;
  font-size: 1.5rem;
  white-space: pre-wrap;
  cursor: pointer;
}
.sidebar__reset {
  margin: 2rem;
  margin-top: 6rem;
  margin-left: 4rem;
  padding: 0.5rem;
  background-color: rgba(217, 214, 214, 0.716);
  cursor: pointer;
  border-radius: 10rem;
  border: none;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  font-size: 1.4rem;
  color: rgb(84, 84, 84);
  visibility: hidden;
  opacity: 0;
  transition: all 1.5s ease-in;
}
.sidebar__reset:hover {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.8rem 1.5rem rgba(0, 0, 0, 0.2);
}
.sidebar__reset:active {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.3rem 1rem rgba(0, 0, 0, 0.2);
}
.sidebar__reset--undo-icon {
  height: 1.5rem;
  width: 1.5rem;
}
.sidebar__reset.visible {
  visibility: visible;
  opacity: 1;
}

@media (max-width: 550px) {
  .sidebar {
    position: fixed;
    display: flex;
    height: 8lvh;
    width: 100%;
    flex-direction: row;
    transition: all 0.5s ease-out;
    align-items: center;
  }
  .sidebar__form {
    flex: 1;
    padding: 1rem;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.5rem;
  }
  .sidebar__form label {
    font-size: 2.1rem;
  }
  .sidebar__form--select {
    padding: 0;
    margin: 0;
    font-size: 1.7rem;
  }
  .sidebar__reset {
    margin: 0 1rem;
    border-radius: 0;
    display: none;
  }
  .sidebar__reset:hover {
    transform: none;
    box-shadow: none;
  }
  .sidebar__reset--undo-icon {
    height: 3rem;
    width: 3rem;
  }
  .sidebar.visible {
    transform: translateY(8lvh);
  }
}
.results {
  grid-row: 2/-1;
  grid-column: 2/-1;
}
.results__title {
  font-size: 2.5rem;
}
.results__grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  gap: 3vw;
  padding: 3vw;
}
.results__title {
  text-align: center;
  padding-top: 5rem;
  padding-bottom: 3rem;
}
.results__album {
  width: clamp(160px, 18vw, 100rem);
  transition: transform 0.75s;
  backface-visibility: hidden;
  scroll-margin-top: 5rem;
}
.results__album--primary-container {
  width: 100%;
}
.results__album--img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  box-shadow: 0.5rem 0.5rem 0.5rem lightgrey;
}
.results__album--text {
  padding: 1rem;
  font-size: 1.6rem;
}
.results__album--text .title {
  font-style: italic;
}
.results__album--extra-info {
  display: none;
  opacity: 0;
  transition: all 3s;
}
.results__album--extra-info td {
  padding: 1.5rem 1.5rem;
}
.results__album--extra-info .description {
  padding: 1rem;
}
.results__album--extra-info .winner {
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4rem;
  gap: 0.5rem;
  animation: expand 2s 3;
  animation-delay: 1s;
}
.results__album--extra-info .winner-mobile {
  display: none;
}
.results__album .trophy {
  height: 2.5rem;
  width: 3.5rem;
}
.results__album .info--close-mobile {
  display: none;
}
.results__album.selected {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  order: -1;
  margin-bottom: 5rem;
}
.results__album.selected .info--close {
  position: absolute;
  top: -2rem;
  right: 2rem;
  font-size: 4.5rem;
  color: #b8b4b4;
  transition: all 1s;
}
.results__album.selected .info--close:hover {
  color: #4e4c4c;
  cursor: pointer;
}
.results__album.selected:hover {
  transform: none;
}
.results__album.selected .results__album--primary-container {
  width: 50%;
  padding: 0 5% 0 10%;
}
.results__album.selected .results__album--img {
  height: 28vw;
  width: 28vw;
  animation: scaleUp 3s;
  animation-fill-mode: forwards;
}
.results__album.selected .results__album--text {
  font-size: 2rem;
  padding: 1.5rem 1rem;
}
.results__album.selected .results__album--extra-info {
  width: 50%;
  padding-right: 4rem;
  display: inline-block;
  font-size: 1.6rem;
  animation: FadeInLeft 3s;
  animation-fill-mode: forwards;
}
.results__album.selected .results__album--extra-info .table--title {
  font-weight: 150;
  font-size: 1.8rem;
}
.results__album.selected .results__album--extra-info .description {
  margin-left: 2rem;
}
.results__album.selected .results__album--extra-info .spotify-button, .results__album.selected .results__album--extra-info .spotify-link {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 2rem;
  margin-left: 2rem;
  padding: 0.5rem;
  background-color: #191414;
  color: white;
  height: 5rem;
  width: 19rem;
  border-radius: 3rem;
  cursor: pointer;
  transition: all 0.5s;
  position: relative;
  z-index: 1;
}
.results__album.selected .results__album--extra-info .spotify-button img, .results__album.selected .results__album--extra-info .spotify-link img {
  height: 3rem;
  width: 3rem;
}
.results__album.selected .results__album--extra-info .spotify-button:hover, .results__album.selected .results__album--extra-info .spotify-link:hover {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.8rem 1.5rem rgba(0, 0, 0, 0.2);
}
.results__album.selected .results__album--extra-info .spotify-link {
  display: none;
  text-decoration: none;
  margin: 0 auto 4rem auto;
}

@media (min-width: 550px) {
  .results__album:hover {
    transform: translateY(-0.5rem);
  }
}
@media (max-width: 550px) {
  .results {
    grid-row: 3/-1;
    grid-column: 1/-1;
    transition: all 0.5s ease-out;
  }
  .results__grid {
    gap: 6vw;
  }
  .results__album {
    scroll-margin-top: 10rem;
  }
  .results__album--text {
    font-size: 1.7rem;
  }
  .results__album--text .title {
    margin-bottom: 0.4rem;
  }
  .results__album.selected {
    height: 93lvh;
    width: 100%;
    max-width: none;
    position: fixed;
    top: 8lvh;
    background-color: white;
    margin: 0 -1rem;
    padding: 2rem 2rem;
    display: inline-block;
    overflow: scroll;
    z-index: 2;
  }
  .results__album.selected .info--close-desktop {
    display: none;
  }
  .results__album.selected .info--close-mobile {
    display: block;
    position: absolute;
    top: -7rem;
    right: 1.5rem;
    font-size: 6rem;
    color: #b8b4b4;
    transition: all 1s;
    z-index: 3;
  }
  .results__album.selected .results__album--primary-container {
    width: 100%;
    padding: 0;
    position: relative;
    margin-top: 4rem;
  }
  .results__album.selected .results__album--img-container {
    text-align: center;
  }
  .results__album.selected .results__album--img {
    height: 60vw;
    width: 60vw;
    animation: none;
    transition: all 3s;
  }
  .results__album.selected .results__album--extra-info {
    width: 100%;
    padding: 0;
    font-size: 1.8rem;
  }
  .results__album.selected .results__album--extra-info .description {
    padding: 0 3rem;
    margin-bottom: 5rem;
  }
  .results__album.selected .results__album--extra-info .winner {
    padding-top: 4rem;
    margin-bottom: 5rem;
  }
  .results__album.selected .results__album--extra-info .winner-mobile {
    display: flex;
  }
  .results__album.selected .results__album--extra-info .winner-desktop {
    display: none;
  }
  .results__album.selected .results__album--extra-info .spotify-button {
    display: none;
  }
  .results__album.selected .results__album--extra-info .spotify-link {
    display: flex;
  }
  .results.shift {
    margin-top: 8rem;
  }
}
@media (max-width: 364px) {
  .results__album {
    flex-grow: 1;
    max-width: 70vw;
  }
}
.spotify-player {
  position: fixed;
  height: 20rem;
  width: 40rem;
  right: 50%;
  bottom: 0;
  z-index: 5;
  transition: all 1.5s ease-in;
  transition-delay: 0s;
  transform: translateX(50%) translateY(40rem);
}
.spotify-player .container {
  position: relative;
}
.spotify-player__close {
  --size: 3.5rem;
  position: absolute;
  bottom: 1rem;
  left: calc(50% - 1.5rem);
  height: var(--size);
  width: var(--size);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: whitesmoke;
  opacity: 0.9;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease-in;
}
.spotify-player__close--cross {
  position: relative;
  color: black;
  opacity: 1;
  bottom: 1rem;
  height: var(--size);
  font-size: var(--size);
  user-select: none;
}
.spotify-player__close:hover {
  transform: translateY(-0.1rem);
  box-shadow: 0 0.5rem 0.5rem rgba(0, 0, 0, 0.2);
}
.spotify-player__close:active {
  outline: none;
  transform: translateY(-0.05rem);
  box-shadow: 0 0.5rem 0.7rem rgba(0, 0, 0, 0.25);
}
.spotify-player.visible {
  display: block;
  transform: translateX(50%) translateY(0);
  transition: all 1s ease-out;
  transition-delay: 1s;
}

@media (max-width: 800px) {
  .spotify-player {
    height: 14rem;
    width: 37.5rem;
  }
  .spotify-player__close {
    --size: 3rem;
    bottom: -0.5rem;
  }
}
@keyframes FadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-1rem);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes scaleUp {
  0% {
    height: 24vw;
    width: 24vw;
  }
  100% {
    height: 28vw;
    width: 28vw;
  }
}
@keyframes expand {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes firework {
  0% {
    transform: translate(-50%, 60vh);
    width: 0.5vmin;
    opacity: 1;
  }
  50% {
    width: 0.5vmin;
    opacity: 1;
  }
  100% {
    width: 45vmin;
    opacity: 0;
  }
}
.firework-1 {
  content: "";
  position: absolute;
  opacity: 0;
  top: 75%;
  left: 60%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background: radial-gradient(circle, rgb(72, 0, 255) 0.2vmin, rgba(0, 0, 0, 0) 0) 50% 0%, radial-gradient(circle, rgb(72, 0, 225) 0.5vmin, rgba(0, 0, 0, 0) 0) 50% 99%, radial-gradient(circle, rgb(72, 0, 225) 0.3vmin, rgba(0, 0, 0, 0) 0) 0% 50%, radial-gradient(circle, rgb(72, 0, 225) 0.2vmin, rgba(0, 0, 0, 0) 0) 99% 50%, radial-gradient(circle, rgb(72, 0, 225) 0.3vmin, rgba(0, 0, 0, 0) 0) 80% 90%, radial-gradient(circle, rgb(72, 0, 225) 0.5vmin, rgba(0, 0, 0, 0) 0) 95% 90%, radial-gradient(circle, rgb(72, 0, 225) 0.5vmin, rgba(0, 0, 0, 0) 0) 10% 60%, radial-gradient(circle, rgb(72, 0, 225) 0.2vmin, rgba(0, 0, 0, 0) 0) 31% 80%, radial-gradient(circle, rgb(72, 0, 225) 0.3vmin, rgba(0, 0, 0, 0) 0) 80% 10%, radial-gradient(circle, rgb(72, 0, 225) 0.2vmin, rgba(0, 0, 0, 0) 0) 90% 23%, radial-gradient(circle, rgb(72, 0, 225) 0.3vmin, rgba(0, 0, 0, 0) 0) 45% 20%, radial-gradient(circle, rgb(72, 0, 225) 0.5vmin, rgba(0, 0, 0, 0) 0) 13% 24%;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: firework 2s 3;
  animation-fill-mode: forwards;
  animation-delay: 0.7s;
}

.firework-2 {
  --top: 60vh;
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background: radial-gradient(circle, rgb(255, 38, 0) 0.2vmin, rgba(0, 0, 0, 0) 0) 50% 0%, radial-gradient(circle, rgb(172, 27, 2) 0.5vmin, rgba(0, 0, 0, 0) 0) 50% 99%, radial-gradient(circle, rgb(172, 27, 2) 0.3vmin, rgba(0, 0, 0, 0) 0) 0% 50%, radial-gradient(circle, rgb(172, 27, 2) 0.2vmin, rgba(0, 0, 0, 0) 0) 99% 50%, radial-gradient(circle, rgb(172, 27, 2) 0.3vmin, rgba(0, 0, 0, 0) 0) 80% 90%, radial-gradient(circle, rgb(172, 27, 2) 0.5vmin, rgba(0, 0, 0, 0) 0) 95% 90%, radial-gradient(circle, rgb(172, 27, 2) 0.5vmin, rgba(0, 0, 0, 0) 0) 10% 60%, radial-gradient(circle, rgb(172, 27, 2) 0.2vmin, rgba(0, 0, 0, 0) 0) 31% 80%, radial-gradient(circle, rgb(172, 27, 2) 0.3vmin, rgba(0, 0, 0, 0) 0) 80% 10%, radial-gradient(circle, rgb(172, 27, 2) 0.2vmin, rgba(0, 0, 0, 0) 0) 90% 23%, radial-gradient(circle, rgb(172, 27, 2) 0.3vmin, rgba(0, 0, 0, 0) 0) 45% 20%, radial-gradient(circle, rgb(172, 27, 2) 0.5vmin, rgba(0, 0, 0, 0) 0) 13% 24%;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: firework 2s 3;
  animation-fill-mode: forwards;
}

.firework-3 {
  --top: 60vh;
  content: "";
  position: absolute;
  top: 65%;
  left: 40%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background: radial-gradient(circle, rgb(255, 0, 221) 0.2vmin, rgba(0, 0, 0, 0) 0) 50% 0%, radial-gradient(circle, rgb(255, 0, 221) 0.5vmin, rgba(0, 0, 0, 0) 0) 50% 99%, radial-gradient(circle, rgb(255, 0, 221) 0.3vmin, rgba(0, 0, 0, 0) 0) 0% 50%, radial-gradient(circle, rgb(255, 0, 221) 0.2vmin, rgba(0, 0, 0, 0) 0) 99% 50%, radial-gradient(circle, rgb(255, 0, 221) 0.3vmin, rgba(0, 0, 0, 0) 0) 80% 90%, radial-gradient(circle, rgb(255, 0, 221) 0.5vmin, rgba(0, 0, 0, 0) 0) 95% 90%, radial-gradient(circle, rgb(255, 0, 221) 0.5vmin, rgba(0, 0, 0, 0) 0) 10% 60%, radial-gradient(circle, rgb(255, 0, 221) 0.2vmin, rgba(0, 0, 0, 0) 0) 31% 80%, radial-gradient(circle, rgb(255, 0, 221) 0.3vmin, rgba(0, 0, 0, 0) 0) 80% 10%, radial-gradient(circle, rgb(255, 0, 221) 0.2vmin, rgba(0, 0, 0, 0) 0) 90% 23%, radial-gradient(circle, rgb(255, 0, 221) 0.3vmin, rgba(0, 0, 0, 0) 0) 45% 20%, radial-gradient(circle, rgb(255, 0, 221) 0.5vmin, rgba(0, 0, 0, 0) 0) 13% 24%;
  background-size: 0.5vmin 0.5vmin;
  background-repeat: no-repeat;
  animation: firework 2s 3;
  animation-fill-mode: forwards;
  animation-delay: 0.3s;
}

